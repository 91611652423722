import type { StateDeclaration } from '@uirouter/core';
import type { ISiteApis } from '@wix/yoshi-flow-editor';

import { EFilterKeys } from 'api/feed';
import { EGroupPartition } from 'api/groups/types';
import { GroupAppKey } from 'store/groups/types';
import { COMPONENT_ID } from 'common/utils/utils';

import {
  GROUPS_APP_ID,
  MEMBERS_APP_DEF_ID,
  PAID_PLANS_APP_DEF_ID,
} from './config/constants';

const YOSHI_OVERRIDE =
  process.env.NODE_ENV !== 'production'
    ? '?controllersUrlOverride&overridePlatformBaseUrls&petri_ovr&viewerPlatformOverrides&widgetsUrlOverride&debug&ssrIndicator'
    : '?social-groups-ooi-override&ssrOnly&debug';

export async function getApplicationRoutes(site: ISiteApis) {
  const [groupPageUrl, groupsPageUrl, pricingPlansPageUrl, membersPageUrl] =
    await Promise.all([
      getGroupPageUrl(site),
      getGroupsPageUrl(site),
      getPricingPlansPageUrl(site),
      getProfilePageUrl(site),
    ]);

  return [
    {
      name: 'pricing-plans',
      url: pricingPlansPageUrl + '?{appSectionParams}',
    },
    {
      name: 'members',
      abstract: true,
      url: membersPageUrl,
    },
    {
      name: 'members.profile',
      url: '/:memberId',
    },
    {
      name: 'home',
      url: '/',
    },
    {
      abstract: true,
      name: 'social-groups',
      url: YOSHI_OVERRIDE,
    },

    // Groups page routes
    {
      name: 'groups',
      url: groupsPageUrl,
      parent: 'social-groups',
      abstract: true,
      data: {
        sectionId: COMPONENT_ID.GROUP_LIST,
      },
    },
    {
      name: 'groups.list',
      url: `?partition&{title:URIEncoded}&sort&order`,
      params: {
        partition: {
          value: EGroupPartition.ALL,
          squash: true,
        },
        // not using yet, waiting for design
        order: {
          squash: true,
        },
        // sort is described in Groups/router.ts
      },
    },

    // Group page routes
    {
      name: 'group',
      parent: 'social-groups',
      url: `${groupPageUrl}/:slug?autoInviteId&invite&appSectionParams`,
      redirectTo: 'group.discussion.feed',
      params: {
        appSectionParams: {
          dynamic: true,
          type: 'json',
        },
        invite: {
          type: 'bool',
          dynamic: true,
        },
      },
      data: {
        sectionId: COMPONENT_ID.GROUP,
      },
    },
    {
      name: 'group.discussion',
      url: '/discussion',
      abstract: true,
    },
    {
      name: 'group.discussion.feed',
      url: `?${EFilterKeys.TOPICS}&${EFilterKeys.CURSOR}`,
      data: {
        application: GroupAppKey.FEED_APP,
        counter: 'updates',
        title: 'groups-web.discussion',
      },
      params: {
        createPost: {
          type: 'bool',
          dynamic: true,
          value: null,
        },
      },
    },
    {
      name: 'group.discussion.post',
      url: '/:feedItemId?commentId',
      data: {
        application: GroupAppKey.FEED_APP,
      },
    },
    {
      name: 'group.media',
      url: '/media',
      data: {
        title: 'groups-web.media',
        application: GroupAppKey.GALLERY_APP,
      },
    },
    {
      name: 'group.files',
      url: '/files',
      data: {
        title: 'groups-web.files',
        application: GroupAppKey.FILES_APP,
      },
      params: {
        sorting: {
          value: 'POST_DATE',
        },
      },
    },
    {
      name: 'group.about',
      url: '/about',
      data: {
        title: 'groups-web.about',
        application: GroupAppKey.ABOUT_APP,
      },
    },
    {
      name: 'group.members',
      url: '/members?nickname&role&expandJoinedRequests',
      data: {
        title: 'groups-web.members',
        counter: 'members',
        application: GroupAppKey.MEMBERS_APP,
      },
      params: {
        order: {
          value: 'ASC',
          squash: true,
        },
        nickname: {
          type: 'URIEncoded',
          value: '',
          squash: true,
        },
        expandJoinedRequests: {
          type: 'bool',
          dynamic: true,
          value: null,
        },
      },
    },
    {
      name: 'group.events',
      url: '/events',
      data: {
        title: 'groups-web.events',
        application: GroupAppKey.EVENTS_APP,
      },
    },
    {
      name: 'group.custom',
      url: '/custom?',
      redirectTo: 'group.custom.tab',
    },
    {
      name: 'group.custom.tab',
      url: '/:tabId',
      data: {
        title: 'groups-web.custom',
      },
      params: {
        tabId: {
          type: 'string',
          value: 'custom_app',
          squash: true,
        },
      },
    },
  ] as StateDeclaration[];
}

export async function getGroupPageUrl(site: ISiteApis) {
  try {
    const isLegacy = await site.isAppSectionInstalled({
      appDefinitionId: GROUPS_APP_ID,
      sectionId: 'group-page',
    });

    return getComponentUrl(
      site,
      {
        sectionId: isLegacy ? 'group-page' : 'group',
      },
      '/group',
    );
  } catch (error) {
    return '/group';
  }
}

export async function getGroupsPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'groups',
    },
    '/groups',
  );
}

export async function getPricingPlansPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'membership_plan_picker_tpa',
      appDefinitionId: PAID_PLANS_APP_DEF_ID,
    },
    '/plans-pricing',
  );
}

export async function getProfilePageUrl(site: ISiteApis) {
  try {
    const membersApi = await site.getPublicAPI(MEMBERS_APP_DEF_ID);

    return membersApi
      .getMemberPagePrefix({ type: 'public' })
      .then((data: { prefix: string }) => `/${data.prefix}`)
      .catch(() => '/profile');
  } catch (err) {
    return '/profile';
  }
}

export async function getComponentUrl(
  site: ISiteApis,
  sectionIdentifier: {
    sectionId:
      | 'groups'
      | 'group'
      | 'group-page'
      | 'membership_plan_picker_tpa'
      | 'about';
    appDefinitionId?: string; // GROUPS_APP_ID by default
  },
  fallback: string,
) {
  try {
    const { relativeUrl } = await site.getSectionUrl({
      appDefinitionId: sectionIdentifier.appDefinitionId || GROUPS_APP_ID,
      sectionId: sectionIdentifier.sectionId,
    });

    return relativeUrl || fallback;
  } catch (error) {
    return fallback;
  }
}
